import axios from '@/apis/axios';
import { getAccessToken, convertCamelToSnake } from '@/lib/api';

/**
 * 案件一覧取得
 */
export const getContracts = async (option, condition) => {
  const accessToken = await getAccessToken();

  let params = {};
  if (option) {
    params = Object.assign(params, convertCamelToSnake(option));
  }
  if (condition) {
    params = Object.assign(params, convertCamelToSnake(condition));
  }

  const response = await axios
    .get(`/agencies/contracts`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件情報取得
 * @param {Number} contractId 契約ID
 */
export const getContractDetail = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件対応重要事項等説明書取得
 */
export const getContractImportantMatter = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/important_matters`, {
      responseType: 'blob',
      dataType: 'application/pdf',
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  if (!response) return;

  // typeの実態がapplication/octet-streamになっているためpdfに書き換える
  const blob = new Blob([response.data]);

  return blob;
};

/**
 * 案件重要事項等説明書URL取得
 */
export const getContractImportantMatterUrl = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/important_matters/url`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件情報登録
 */
export const postContract = async data => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      `/agencies/contracts`,
      { ...data },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件情報更新
 * @param {Object} condition 更新項目一覧
 */
export const updateContractDetail = async condition => {
  const accessToken = await getAccessToken();

  const contractId = condition.contractId;

  const response = await axios.put(
    `/agencies/contracts/${contractId}`,

    { ...condition },
    { headers: { 'access-token': accessToken } }
  );
  return response.data;
};

/**
 * 案件削除
 */
export const deleteContract = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .delete(`/agencies/contracts/${contractId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 案件ステータス更新
 */
export const putContractStatus = async (contractId, updateParam) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/status`,
      { ...updateParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * プラン情報更新
 */
export const updatePlanDetail = async (contractId, planId, data) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/plans/${planId}`,
      { ...data },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * メール・SMS送信
 * @param {Object} condition 送信情報一覧
 */
export const sendMessage = async condition => {
  const accessToken = await getAccessToken();

  const contractId = condition.contractId;

  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/contract_submits`,
      { ...condition },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件申込内容取得
 */
export const getContractApplicationForm = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/application_form`, {
      responseType: 'blob',
      dataType: 'application/pdf',
      headers: {
        'access-token': accessToken,
      },
    })
    .catch(error => {
      throw error.response.data;
    });

  const blob = new Blob([response.data]);

  return blob;
};

/**
 * 案件申込内容URL取得
 */
export const getContractApplicationFormUrl = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/application_form/url`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 申込内容以外データ情報取得処理
 */
export const getExtraPictureList = contractId =>
  new Promise(async (resolve, reject) => {
    const accessToken = await getAccessToken();

    const params = { ['contractId']: contractId };

    const response = await axios.get(
      `/agencies/contracts/${contractId}/extra_files/list`,
      {
        params: { ...params },
        headers: {
          'access-token': accessToken,
        },
      }
    );

    resolve(response.data);
  });

/**
 * 申込内容以外データ取得処理
 */
export const getExtraPicture = (contractId, pictureId) =>
  new Promise(async (resolve, reject) => {
    const accessToken = await getAccessToken();

    const params = { ['id']: pictureId };

    const response = await axios.get(
      `/agencies/contracts/${contractId}/extra_files/file`,
      {
        params: { ...params },
        responseType: 'arraybuffer',
        headers: {
          'access-token': accessToken,
        },
      }
    );
    if (!response) return resolve(null);

    resolve(new Blob([response.data]));
  });

/**
 * 申込内容以外データ登録処理
 */
export const registerExtraPictures = async (
  contractId,
  extraFileDetails,
  picture
) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('files[0]', picture);
  form.append('extraFileDetails', JSON.stringify(extraFileDetails));

  const response = await axios
    .post(`/agencies/contracts/${contractId}/register_extra_files`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 申込内容以外データ内容編集処理
 */
export const editExtraPictures = async (
  contractId,
  extraFileId,
  extraFileDetails
) => {
  const accessToken = await getAccessToken();
  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/register_extra_files/${extraFileId}`,
      { ...extraFileDetails },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 申込内容以外データ削除処理
 */
export const deleteExtraPictures = async (contractId, extraFileId) => {
  const accessToken = await getAccessToken();
  const response = await axios
    .delete(
      `/agencies/contracts/${contractId}/register_extra_files/${extraFileId}`,
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * ロックアウト解除処理
 */
export const lockoutRelease = async contractId => {
  const accessToken = await getAccessToken();
  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/lockout_release`,
      {},
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
